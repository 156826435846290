import types from "constants/actionTypes";
import { Presence } from "phoenix";
import * as R from "ramda";
import { camelizeKeys } from "humps";
import { formatThousands } from "utils/common";

// matchStatus :: 'awaiting' | 'active' | 'finished'
const MAX_PLAYERS = 4;
const uuidRegexp = new RegExp(
  "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}"
);

const stageCommunityRanges = {
  flop: {
    min: 0,
    max: 2,
    length: 3,
  },
  turn: {
    min: 3,
    max: 3,
    length: 1,
  },
  river: {
    min: 4,
    max: 4,
    length: 1,
  },
};

const convertExceptUUID = (key, convert) =>
  uuidRegexp.test(key) ? key : convert(key);
const seat = (players, maxPlayers) => {
  return [
    ...players,
    ...R.times(
      (e) => ({ id: e, invisible: true }),
      maxPlayers - players.length
    ),
  ];
};

const getUserNames = (players) =>
  R.map(([{ username }]) => username, R.groupBy(R.prop("id"), players));

const truncateMs = R.pipe(
  R.split("."),
  R.head
);

const formatMessage = (effectType, payload, bet) => {
  if (effectType === "check") {
    return "Checked";
  }
  if (effectType === "fold") {
    return "Folded";
  }
  if (effectType === "call") {
    return "Called";
  }

  // raise
  if (bet === 0) {
    return `Bet ${formatThousands(payload.amount)}`;
  }

  return `Raised to ${formatThousands(bet + payload.amount)}`;
};

const prepareCommunityCardsLogs = (
  { preparedCommunityCards = [], effectStorage, stage, preparedStreetCards },
  currentTime
) => {
  const reduceIndexed = R.addIndex(R.reduce);
  const { messages, cards } = reduceIndexed(
    ({ cards, messages }, card, i) => {
      if (
        stage in stageCommunityRanges &&
        cards[stage].cards.length < stageCommunityRanges[stage].length
      ) {
        if (
          i >= stageCommunityRanges[stage].min &&
          i <= stageCommunityRanges[stage].max
        ) {
          cards[stage].cards = [...cards[stage].cards, card];

          cards[stage].time = currentTime;
        }
      }

      // const messageCards = R.last(R.filter((cards) => cards.length, cards));
      return {
        cards: cards,
        messages: R.mapObjIndexed((cards, stage) => {
          if (cards.cards.length === stageCommunityRanges[stage].length) {
            return {
              type: "streetUpdate",
              stage: stage,
              cards: cards.cards,
              time: truncateMs(cards.time),
            };
          }
        }, cards),
      };
    },
    { messages: [], cards: { ...preparedStreetCards } },
    preparedCommunityCards
  );

  return {
    messages: Object.values(messages).filter(
      (message) => message !== undefined
    ),
    cards: cards,
  };
};

const pocketCardsKeyToLower = (pocketCards) => {
  if (pocketCards === null || pocketCards === undefined) {
    return [];
  }
  if (!Array.isArray(pocketCards)) {
    return Object.fromEntries(
      Object.entries(pocketCards).map(([key, val]) => [key.toLowerCase(), val])
    );
  }

  return pocketCards;
};

const preparePocketCards = (
  { pocketCards = [] },
  currentUserId = "",
  currentTime
) => {
  console.log(currentUserId);
  const parsedId = R.replace(/-/g, "", currentUserId);
  pocketCards = pocketCardsKeyToLower(pocketCards)[parsedId];

  if (pocketCards === undefined || pocketCards.length <= 0) {
    return {
      messages: [],
      cards: [],
    };
  }

  const reduceIndexed = R.addIndex(R.reduce);
  const { messages, cards } = reduceIndexed(
    ({ cards, messages }, card, i) => {
      if (cards.length < 2) {
        cards = [...cards, card];
      }

      return {
        cards: cards,
        messages: [
          {
            type: "streetUpdate",
            stage: "Start",
            cards: cards,
            // time: truncateMs(cards.time)
          },
        ],
      };
    },
    { cards: [], messages: [] },
    pocketCards
  );

  return {
    messages: Object.values(messages).filter(
      (message) => message !== undefined
    ),
    cards: cards,
  };
};

export const prepareLogs = (
  games,
  players,
  preparedStreetCards,
  currentTime,
  currentPlayer
) => {
  // console.log("brooozzep", games);

  const usernames = getUserNames(players);

  const { data, cards } = R.reduceRight(
    (game, { data, gameNumber }) => {
      const communityCardsLogs = prepareCommunityCardsLogs(game, currentTime);

      const pocketCardsLogs = preparePocketCards(
        game,
        currentPlayer,
        currentTime
      );

      const currentGameLogs = prepareLogsForSingleGame(
        usernames,
        game,
        gameNumber + 1,
        currentTime,
        currentPlayer
      );

      let logs = R.concat(currentGameLogs, communityCardsLogs.messages);
      logs = R.concat(logs, pocketCardsLogs.messages);

      return {
        data: [
          ...(data.length === 0 ? data : [...data, { type: "divider" }]),
          ...R.filter(
            (a) => a !== false,
            R.sort((a, b) => {
              if (b.type === "init") {
                return 0;
              }

              if (a.time !== undefined && b.time !== undefined) {
                return (
                  Date.parse("1970-01-01T" + a.time + "Z") -
                  Date.parse("1970-01-01T" + b.time + "Z")
                );
              }

              if (a.stage === "Start") {
                return -1;
              }

              if (a.type === "showdown" || a.type === "final") {
                return 1;
              }

              if (b.type === "showdown") {
                return -1;
              }

              if (a.type === b.type) {
                return 0;
              }

              if (a.time === undefined) {
                return -1;
              }
            }, logs)
          ),
        ],
        gameNumber: gameNumber + 1,
        cards: communityCardsLogs.cards,
      };
    },
    { data: [], gameNumber: 0, cards: preparedStreetCards },
    games
  );

  return { logs: data, cards: cards };
};

const prepareWinners = (usernames, winnerUsernames, winners, currentTime) => {
  const { winnersWithAmount } = R.reduceRight(
    ([id, amount], { winnersWithAmount }) => {
      return {
        winnersWithAmount: [
          ...winnersWithAmount,
          [usernames[id]] + ` (Pot: ${amount})`,
        ],
      };
    },
    { winnersWithAmount: [] },
    R.toPairs(winners)
  );

  return {
    type: "final",
    message:
      (R.length(winnersWithAmount) === 1 ? "Winner: " : "Winners: ") +
      winnersWithAmount.join(", "),
    time: truncateMs(currentTime),
  };
};

const prepareShowdownHands = (
  usernames,
  communityCards = [],
  pocketCards,
  winners,
  currentPlayerId
) => {
  const { logs } = R.reduceRight(
    ([id, username], { logs }) => {
      const parsedId = R.replace(/-/g, "", id);
      pocketCards = pocketCardsKeyToLower(pocketCards, id);

      if (Array.isArray(pocketCards)) {
        return {
          logs: [
            {
              type: "showdown",
              cards: pocketCards,
              stage: id === currentPlayerId ? "Your hand:" : username + ":",
            },
          ],
        };
      }
      console.log(pocketCards);
      console.log(parsedId);
      console.log(pocketCards[parsedId]);
      if (
        (pocketCards[parsedId] !== undefined ||
          pocketCards[parsedId].length > 0) &&
        pocketCards[parsedId][0].suit !== "none"
      ) {
        return {
          logs: [
            ...logs,
            {
              type: "showdown",
              cards: pocketCards[parsedId],
              stage: id === currentPlayerId ? "Your hand:" : username + ":",
            },
          ],
        };
      }

      return {
        logs: logs,
      };
    },
    { logs: [] },
    R.toPairs(usernames)
  );

  return R.concat(logs, [
    communityCards.length > 0 && {
      type: "showdown",
      cards: communityCards,
      stage: "Community cards:",
    },
  ]);
};

const prepareLogsForSingleGame = (
  usernames,
  {
    effectStorage,
    ante,
    smallBlind,
    bigBlind,
    winners,
    showdownHands,
    pocketCards,
    preparedCommunityCards,
  },
  gameNumber,
  currentTime,
  currentPlayer
) => {
  console.log(pocketCards);
  console.log("ppok", pocketCards);
  const { result } = R.reduceRight(
    (
      [effectType, playerId, { stage, metadata, ...payload }],
      { result, bet, previousStage }
    ) => {
      // don't show ante and autocheck actions
      if (stage === "pre_setup" || (effectType === "check" && payload.auto)) {
        return { result, previousStage: stage, bet };
      }

      if (stage === "setup") {
        return {
          result: [
            ...result,
            {
              type: "action",
              time: truncateMs(metadata.time),
              username: usernames[playerId],
              message:
                bet === 0
                  ? `Placed SB ${formatThousands(smallBlind)}`
                  : `Placed BB ${formatThousands(bigBlind)}`,
            },
          ],
          previousStage: stage,
          bet: bigBlind,
        };
      }

      const initialBet =
        previousStage === stage || stage === "preflop" ? bet : 0;
      const newBet =
        effectType === "raise" ? initialBet + payload.amount : initialBet;

      return {
        result: [
          ...result,
          {
            type: "action",
            time: truncateMs(metadata.time),
            username: usernames[playerId],
            message: formatMessage(effectType, payload, initialBet),
          },
        ],
        bet: newBet,
        previousStage: stage,
      };
    },
    {
      result: [{ type: "init", message: `Hand #${gameNumber} started` }],
      bet: 0,
      previousStage: null,
    },
    effectStorage
  );

  const winnerUsernames = R.map((id) => usernames[id], R.keys(winners));
  return R.length(winnerUsernames) === 0
    ? result
    : [
        ...result,
        ...prepareShowdownHands(
          usernames,
          preparedCommunityCards,
          pocketCards,
          winners,
          currentPlayer
        ),
        prepareWinners(usernames, winnerUsernames, winners, currentTime),
      ];
};

const splitByDenomination = (v, nominals = [10000, 5000, 2500, 1000, 500]) => {
  return nominals.map((nom) => {
    const quotient = Math.floor(v / nom);
    const remainder = v % nom;
    v = remainder;
    return [nom, quotient];
  });
};

const playerFromPresence = (pair) => {
  const [id, payload] = pair;
  const username =
    (payload && payload.details && payload.details.username) || "Guest";
  const profileImage =
    payload && payload.details && payload.details.profile_image;
  const timeBanks =
    payload && payload.metas && payload.metas[0] && payload.metas[0].time_banks;

  return { id, username, profileImage, timeBanks };
};
const presencesToPlayers = R.pipe(
  R.toPairs,
  R.map(playerFromPresence)
);
const rotate = R.pipe(
  R.splitAt,
  R.reverse,
  R.flatten
);

// rotateUntilAtFront :: (a -> Boolean) -> [a]
const rotateUntilAtFront = (f, coll) => {
  const idx = R.findIndex(f)(coll);
  return idx === -1 ? coll : rotate(idx, coll);
};

const prepareCurrentPeriod = (periodManager) => {
  const periods = camelizeKeys(periodManager).periods;
  const currentPeriod = periods[0];
  const currentGamingPeriodNumber = periods.filter(
    (period) => period.type !== "timer"
  ).length;
  const numberOfGamingPeriods =
    currentGamingPeriodNumber +
    periodManager.sequence.filter(
      ([_name, _duration, type]) => type !== "timer"
    ).length;

  return {
    type: currentPeriod.type,
    name: currentPeriod.name,
    currentGameNumber: currentPeriod.gameIds.length,
    currentGamingPeriodNumber,
    numberOfGamingPeriods,
    timeoutAt: currentPeriod.overdueAt,
    duration: currentPeriod.duration,
  };
};
const camelizePreviousGame = ({
  ante,
  big_blind: bigBlind,
  small_blind: smallBlind,
  effect_storage: effectStorage,
  showdown_hands: showdownHands,
  winners,
}) => ({ ante, bigBlind, smallBlind, effectStorage, showdownHands, winners });

const moveCurrentGameToPreviousGames = ([state, payload]) => {
  const {
    previousGames,
    matchStatus,
    smallBlind,
    bigBlind,
    ante,
    winners,
    showdownHands,
    effectStorage,
    communityCards,
    preparedStreetCards,
    pocketCards,
  } = state;
  if (matchStatus === "awaiting") {
    return [state, payload];
  }
  return [
    {
      ...state,
      preparedStreetCards: {
        flop: { cards: [], time: "" },
        turn: { cards: [], time: "" },
        river: { cards: [], time: "" },
      },
      pocketCards: [],
      previousGames: [
        {
          smallBlind,
          bigBlind,
          ante,
          winners,
          showdownHands,
          effectStorage,
          preparedCommunityCards: communityCards,
          preparedStreetCards: preparedStreetCards,
          pocketCards: pocketCards,
        },
        ...previousGames,
      ],
    },
    payload,
  ];
};

const incrementCurrentGameNumber = ([state, payload]) => {
  return [
    {
      ...state,
      period: {
        ...state.period,
        currentGameNumber: state.period.currentGameNumber + 1,
      },
    },
    payload,
  ];
};

const initialState = {
  players: seat([], MAX_PLAYERS),
  seats: [],
  presences: {},
  currentTime: null,
  communityCards: [],
  communityCardsTimeoutMatrix: [0, 0, 0, 0, 0],
  ante: 500,
  smallBlind: 500,
  bigBlind: 1000,
  currentEffectTimeoutAt: null,
  expectedEffectPayload: {
    validEffectTypes: [],
    minRaiseThreshold: 0,
  },
  profileImages: {},
  scores: [],
  period: {
    name: "countdown",
    type: "timer",
    currentGamingPeriodNumber: 0,
    currentGameNumber: 0,
    numberOfGamingPeriods: 0,
    timeoutAt: null,
    duration: null,
  },
  pot: 0,
  logs: [],
  previousGames: [],
  matchStatus: "awaiting",
  gameStatus: "awaiting",
  currentPlayer: {},
  raisePanel: {
    500: 0,
    1000: 0,
    2500: 0,
    5000: 0,
    10000: 0,
  },
  timeBanks: {},
  preparedStreetCards: {
    flop: { cards: [], time: "" },
    turn: { cards: [], time: "" },
    river: { cards: [], time: "" },
  },
  preparedCommunityCards: [],
  pocketCards: [],
};

const isSuit = (suit) => R.propEq("suit", suit);
const isRank = (rank) => R.propEq("rank", rank);
const isSameCard = ({ rank, suit }) => R.allPass([isSuit(suit), isRank(rank)]);

const ensureCardHighlighted = (winnerHands) => (card) => ({
  ...card,
  highlighted: R.any(isSameCard(card))(winnerHands),
});

const preparePlayerScore = ([id, score]) => ({
  id,
  username: id,
  matchScore: score,
  gameScore: null,
  gameValue: null,
  gameAggression: null,
  reachedShowDown: null,
});
const prepareScores = R.pipe(
  R.toPairs,
  R.map(preparePlayerScore)
);

export default function(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case types.MATCH_START: {
      const { scoreboard } = action.payload;
      console.log("it is what it is", scoreboard);
      const scores = scoreboard ? prepareScores(scoreboard) : state.scores;

      return {
        ...state,
        scores,
      };
    }

    case types.UPDATE_RAISE_AMOUNT: {
      const { amount } = action.payload;
      const k = Math.abs(amount);

      return {
        ...state,
        raisePanel: {
          ...state.raisePanel,
          [k]:
            amount > 0
              ? R.inc(state.raisePanel[k])
              : R.dec(state.raisePanel[k]),
        },
      };
    }

    case types.CLEAR_RAISE_AMOUNT: {
      return {
        ...state,
        raisePanel: {
          ...initialState.raisePanel,
        },
      };
    }

    case types.RAISE_ALL_IN: {
      const { amount } = action.payload;

      return {
        ...state,
        raisePanel: {
          ...initialState.raisePanel,
          ...R.fromPairs(splitByDenomination(amount)),
        },
      };
    }

    case types.GAME_START: {
      const [newState] = R.pipe(
        moveCurrentGameToPreviousGames,
        incrementCurrentGameNumber
      )([state, action.payload]);

      return newState;
    }

    case types.PERIOD_SHIFT: {
      const { period_manager } = action.payload;
      return {
        ...state,
        period: prepareCurrentPeriod(period_manager),
      };
    }

    case types.MATCH_FINISH: {
      const { awards, places, logs } = action.payload;
      const { currentPlayer } = state;

      return {
        ...state,
        currentPlayer: {
          ...currentPlayer,
          points: awards[currentPlayer.id],
          place: places[currentPlayer.id],
        },
        matchStatus: "finished",
      };
    }
    
    case types.TEAM_SCORE: {
      console.log("INSANE");
    }
    case types.ROOM_STATE: {
      const {
        current_time,
        game,
        effect_timeout,
        currentUserID,
        scoreboard,
        period_manager,
        lobby
      } = action.payload;
      if (lobby) {
        localStorage.setItem("lobby", JSON.stringify(lobby));
      }
      const { winners, showdown_hands: showdownHands } = game;
      const transformedGame = camelizeKeys(game);
      const transformedEffectTimeout =
        camelizeKeys(effect_timeout, convertExceptUUID) || {};
      const activeTimeBanks =
        transformedEffectTimeout.currentSource &&
        transformedEffectTimeout.currentSource[1];
      const timeBanks = transformedEffectTimeout.timeBanks || state.timeBanks;

      const {
        ante,
        smallBlind,
        bigBlind,
        communityCards = [],
        pot,
        players = [],
        expectedEffectPayload,
        roundIsOpen,
        effectStorage = [],
        stage,
        pocketCards,
      } = transformedGame;

      // const filterIndexed = R.addIndex(R.filter);
      // const communityCardStages = filterIndexed((n, i) => i >= stageCommunityRanges[stage].min && i <= stageCommunityRanges[stage].max, communityCards);

      const currentTime = current_time || state.currentTime;

      const winnerIDs = R.keys(winners);
      const winnerHands = R.pipe(
        R.pick(winnerIDs),
        R.values,
        R.uniq,
        R.flatten
      )(showdownHands);

      const preparedCommunityCards = communityCards.map(
        ensureCardHighlighted(winnerHands)
      );

      // This is function, we need to calculate card appearance animation.
      const getTimeoutOfCardAppearance = (
        position,
        previousCardsCount,
        currentCardsCount,
        winnersPresent
      ) => {
        if (winnersPresent && currentCardsCount < 5) {
          return 0;
        }
        const cardsDelta = currentCardsCount - previousCardsCount;
        // custom rule for all-in: drop first 3 cards at the same time
        if (cardsDelta > 1 && currentCardsCount === 5) {
          if (position <= 2) {
            return Math.max(0, position - previousCardsCount) * 600;
          }

          if (position === 3) {
            return Math.max(0, position - previousCardsCount) * 1300;
          }
          return Math.max(0, position - previousCardsCount) * 1600;
        }

        return Math.max(0, position - previousCardsCount) * 600;
      };

      const communityCardsTimeoutMatrix = [...Array(5).keys()].map((key) =>
        getTimeoutOfCardAppearance(
          key,
          state.communityCards.length,
          preparedCommunityCards.length,
          !R.isEmpty(winners)
        )
      );

      const profileImages =
        action.payload.profile_images || state.profileImages;
      const preparedPlayers = players.map((player) => {
        const userTimeBanks = timeBanks[player.id];

        return {
          ...player,
          profileImage: profileImages[player.id],
          winner: R.any((id) => player.id === id)(winnerIDs),
          cards: player.cards
            .map(ensureCardHighlighted(winnerHands))
            .map((e) => ({ ...e, updated: new Date() })),
          timeBanks: userTimeBanks,
        };
      });
      const currentPlayer =
        R.find(R.propEq("id", currentUserID))(preparedPlayers) || {};

      const period = period_manager
        ? R.isEmpty(period_manager.periods)
          ? initialState.period
          : prepareCurrentPeriod(period_manager)
        : state.period;

      const scores = scoreboard ? prepareScores(scoreboard) : state.scores;
      console.log("it is what it is", scoreboard);

      const canCheckInCurrentStage =
        Math.min(
          ...R.pluck(
            "stack",
            R.filter(
              (player) =>
                player.status === "playing" || player.status === "acted",
              preparedPlayers
            )
          )
        ) === currentPlayer.stack &&
        R.none(
          ([effectName, userId, payload]) =>
            userId === currentUserID && payload.stage === stage,
          effectStorage
        );
      const previousGames = action.payload.previous_games
        ? R.map(camelizePreviousGame, action.payload.previous_games)
        : state.previousGames;

      const { logs, cards } = prepareLogs(
        [
          {
            bigBlind,
            smallBlind,
            ante,
            effectStorage,
            winners,
            showdownHands,
            preparedCommunityCards,
            preparedStreetCards: state.preparedStreetCards,
            stage,
            pocketCards,
          },
          ...previousGames,
        ],
        preparedPlayers,
        state.preparedStreetCards,
        effectStorage[0] !== undefined
          ? effectStorage[0][2].metadata.time
          : new Date().toTimeString(),
        currentPlayer.id
      );

      return {
        ...state,
        period,
        effectStorage,
        showdownHands,
        previousGames,
        ante,
        lobby,
        profileImages,
        smallBlind,
        bigBlind,
        currentTime,
        roundIsOpen,
        logs,
        communityCards: preparedCommunityCards,
        preparedCommunityCards: preparedCommunityCards,
        communityCardsTimeoutMatrix,
        pot,
        timeDelta: currentTime - performance.now(),
        scores,
        expectedEffectPayload,
        currentEffectTimeoutAt: transformedEffectTimeout.timeoutAt || null,
        timeoutDuration: transformedEffectTimeout.timeout || 0,
        activeTimeBanks: activeTimeBanks,
        timeBanks: timeBanks,
        currentPlayer: { canCheckInCurrentStage, ...currentPlayer },
        players: rotateUntilAtFront(
          R.propEq("id", currentUserID),
          seat(preparedPlayers, MAX_PLAYERS)
        ),
        matchStatus: R.isEmpty(game) ? "awaiting" : "active",
        gameStatus: R.isEmpty(game) ? "awaiting" : "active",
        winners,
        preparedStreetCards: cards,
        pocketCards: pocketCards,
      };
    }

    case types.UPDATE_TIME_BANKS: {
      const { effect_timeout, current_time } = action.payload;
      const { players, currentPlayer } = state;
      const currentTime = current_time || state.currentTime;
      const transformedEffectTimeout = camelizeKeys(
        effect_timeout,
        convertExceptUUID
      );
      const { currentSource, timeBanks } = transformedEffectTimeout;
      const activeTimeBanks = currentSource[1];
      const newPlayers = R.map((player) => ({
        ...player,
        timeBanks: timeBanks[player.id] || {},
      }))(players);
      const newCurrentPlayer = {
        ...currentPlayer,
        timeBanks: timeBanks[currentPlayer.id],
      };

      return {
        ...state,
        currentEffectTimeoutAt: transformedEffectTimeout.timeoutAt || null,
        timeoutDuration: transformedEffectTimeout.timeout || 0,
        timeDelta: currentTime - performance.now(),
        players: newPlayers,
        currentPlayer: newCurrentPlayer,
        activeTimeBanks: activeTimeBanks,
        timeBanks: timeBanks,
      };
    }

    // TODO: Check if we need it?
    // case types.TOGGLE_TIME_BANK: {
    //   const { currentUserID, timeBank } = action.payload
    //   const { players, currentPlayer, timeBanks } = state
    //
    //   const player = R.find(R.propEq('id', currentUserID))(players)
    //   const playerIndex = R.findIndex(R.propEq('id', currentUserID))(players)
    //
    //   const playerTimeBanks = player.timeBanks
    //   const playerTimeBank = playerTimeBanks[timeBank]
    //   const newPlayerTimeBanks = {
    //     ...playerTimeBanks,
    //     [timeBank]: {...playerTimeBank, active: !playerTimeBank.active}
    //   }
    //   const newPlayers = R.update(playerIndex, {...player, timeBanks: newPlayerTimeBanks})(players)
    //
    //   return {
    //     ...state,
    //     players: newPlayers,
    //     currentPlayer: {...currentPlayer, timeBanks: newPlayerTimeBanks}
    //   }
    // }

    case types.UPDATE_SCOREBOARD: {
      console.log("it is what it is", scoreboard);

      const isObjectNotEmpty = (obj) => {
        return Object.keys(obj).length > 0;
      };

      const calculateGameScores = R.map((score) => {
        const prevScore = R.find(R.propEq("id", score.id))(state.scores);
        // const valDiff = (
        //   score.matchScore.value - prevScore.matchScore.value
        // ).toFixed(3);
        return {
          ...score,
          gameScore: score.matchScore.points - prevScore.matchScore.points,
          gameValue: score.matchScore.value.toFixed(2),
          reachedShowDown: isObjectNotEmpty(state.showdownHands),
          gameAggression: score.matchScore.aggression,
        };
      });

      const { scoreboard } = action.payload;
      const scores = R.pipe(
        prepareScores,
        calculateGameScores
      )(scoreboard);

      return {
        ...state,
        scores: scores,
        gameStatus: "finished",
      };
    }

    case types.PRESENCE_STATE: {
      return {
        ...state,
        presences: Presence.syncState({}, action.payload),
      };
    }

    case types.PRESENCE_DIFF: {
      const presences = Presence.syncDiff(state.presences, action.payload);
      const { currentUserID } = action.payload;

      const players =
        state.matchStatus === "awaiting"
          ? rotateUntilAtFront(
              R.propEq("id", currentUserID),
              seat(presencesToPlayers(presences), MAX_PLAYERS)
            )
          : state.players;

      return {
        ...state,
        presences,
        players,
      };
    }

    case types.CLEAR_MATCH_SHOW_STATE: {
      return {
        ...initialState,
        preparedStreetCards: {
          flop: { cards: [], time: "" },
          turn: { cards: [], time: "" },
          river: { cards: [], time: "" },
        },
      };
    }

    case types.TOGGLE_AUDIO: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
