import React, { Fragment, useState } from "react";
import { TableRoot, TableRow, TableCell, TableCellContent } from "../ui";
import styled from "styled-components";
import * as breakpoints from "constants/sizes";
import { Select, Tooltip } from "antd";
import ordinal from "ordinal-js";
import Star from "../../Star";

const CellThemes = {
  grey: {
    content: {
      textColor: "#192e38",
      backgroundColor: "#efefef",
    },
    percent: {
      new: {
        textColor: "#192e38",
        backgroundColor: "#e5e5e5",
      },
      old: {
        textColor: "#EDC8C8",
        backgroundColor: "#e5e5e5",
      },
      textColor: "#192e38",
      backgroundColor: "#e5e5e5",
    },
  },
  green: {
    content: {
      textColor: "#5DC05E",
      backgroundColor: "#00df6a",
    },
    percent: {
      new: {
        textColor: "#5DC05E",
        backgroundColor: "#00df6a",
      },
      old: {
        textColor: "#C5E3C6",
        backgroundColor: "#00df6a",
      },
      textColor: "#5DC05E",
      backgroundColor: "#00df6a",
    },
  },
  red: {
    content: {
      textColor: "#E06666",
      backgroundColor: "#ffe6ec",
    },
    percent: {
      textColor: "#E06666",
      backgroundColor: "#f92255",

      new: {
        textColor: "#E06666",
        backgroundColor: "#E06666",
      },
      old: {
        textColor: "#E06666",
        backgroundColor: "#EDC8C8",
      },
    },
  },
  fontSize: {
    content: "36px",
    percent: "30px",
  },
  fontWeight: {
    content: "bold",
    percent: "normal",
  },
};

export function getNumberWithOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const renderAsColored = (color, render, isPercent) => (
  <ContentCell color={color} isPercent={isPercent} style={{ fontWeight: 200 }}>
    {render()}
  </ContentCell>
);
const renderCalculatedPercentile = (value, color) => {
  if (isNaN(value)) {
    return <div />;
  }

  if (value == 0) {
    return <ContentCell style={{ fontSize: "50px" }}>-</ContentCell>;
  }

  const val =
    value >= 100 || value <= -100 || value === 0
      ? Math.trunc(value)
      : value.toFixed(2);
  const sign = value > 0 ? "+" : "";
  return renderAsColored(color, () => `${sign}${val}%`, true);
};

const calculatePercentile = (value) => (1 - value) * 100;
const renderAsPercentile = (value, color) =>
  renderCalculatedPercentile(calculatePercentile(value), color);

const getCellThemeFromColor = (color) =>
  color === 0
    ? CellThemes.grey
    : color > 0
    ? CellThemes.green
    : color < 0 && CellThemes.red;

const ContentCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
  font-family: Play;

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 20px !important;
  }

  @media (max-width: 768px) {
    font-size: 18px !important;
  }
`;

const Divider = styled.span`
  background-color: #192e38;
  content: "";
  max-width: 10px;
  flex-grow: 1;
`;

const ValueSlider = styled.div`
  height: 100%;
  width: ${(props) => props.value}% !important;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  float: right;
`;

const ValueSliderWrapper = styled.div`
  height: 10px;
  width: 100%;
  background: #F6F7F7;
  border:radius: 4px;
  self-align: center;
`;

const SliderColumns = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 3px;
`;

// const GraphRow = ({ title, description, newValue, oldValue }) => {
//   let [hovering, setHovering] = useState(false);

//   const progressValue = newValue - oldValue;

//   return (
//     <NormalTableRow
//       onMouseOver={() => {
//         setHovering(true);
//       }}
//       onMouseOut={() => {
//         setHovering(false);
//       }}
//     >
//       <TableCell className="hide" />
//       {/*<TableCell><TableCellContent align={"right"} padding={"10px 0 10px 0"}><NegativeValueSlider value={-value} /></TableCellContent></TableCell>*/}
//       {/*<Divider />*/}
//       <TableCell>
//         <TableCellContent
//           align={"right"}
//           style={{
//             cursor: "pointer",
//             color: "#566770",
//             fontSize: "24px !important",
//             fontWeight: "300 !important",
//             fontFamily: "ContraxHV",
//           }}
//         >
//           {title}
//         </TableCellContent>
//         <div style={{ position: "relative" }}>
//           {hovering ? (
//             <div
//               style={{
//                 position: "absolute",
//                 width: "200px",
//                 left: 0,
//                 background: "rgba(200,200,200,.8)",
//                 padding: "10px",
//                 cursor: "pointer",
//                 fontSize: "1em",
//                 marginTop: "-30px",
//                 marginLeft: "50px",
//               }}
//             >
//               <Tooltip>{description}</Tooltip>
//             </div>
//           ) : null}
//         </div>
//       </TableCell>

//       <TableCell>
//         <TableCellContent
//           color={
//             newValue > oldValue
//               ? CellThemes.green.percent.textColor
//               : newValue < oldValue
//               ? CellThemes.red.percent.textColor
//               : CellThemes.grey.percent.textColor
//           }
//           align={"center"}
//           style={{ fontWeight: "200 !important" }}
//         >
//           {renderCalculatedPercentile(progressValue, 0)}
//         </TableCellContent>
//       </TableCell>

//       {title != "Experience" ? (
//         <>
//           <TableCell className="hide medium">
//             <TableCellContent align={"left"} padding={"10px 0 10px 0"}>
//               <SliderColumns>
//                 <ValueSliderWrapper>
//                   <ValueSlider
//                     value={oldValue}
//                     color={
//                       newValue > oldValue
//                         ? CellThemes.green.percent.old.textColor
//                         : newValue < oldValue
//                         ? CellThemes.red.percent.old.backgroundColor
//                         : CellThemes.grey.percent.backgroundColor
//                     }
//                   />
//                 </ValueSliderWrapper>
//                 <ValueSliderWrapper>
//                   <ValueSlider
//                     value={newValue}
//                     color={
//                       newValue > oldValue
//                         ? CellThemes.green.percent.new.textColor
//                         : newValue < oldValue
//                         ? CellThemes.red.percent.new.backgroundColor
//                         : CellThemes.grey.percent.backgroundColor
//                     }
//                   />
//                 </ValueSliderWrapper>
//               </SliderColumns>
//             </TableCellContent>
//           </TableCell>
//         </>
//       ) : (
//         <>
//           <TableCell className="hide medium">
//             <TableCellContent
//               color="#1E2E37"
//               style={{ fontFamily: "ContraxSb" }}
//             >
//               +1
//             </TableCellContent>
//           </TableCell>
//         </>
//       )}

//       <TableCell>
//         <TableCellContent align={"left"} color="#566770">
//           <ContentCell style={{ fontSize: "28px" }}>
//             {newValue.toFixed(2)}%
//           </ContentCell>
//         </TableCellContent>
//       </TableCell>

//       <TableCell className="hide" />
//     </NormalTableRow>
//   );
// };

const NormalTableRow = styled(TableRow)`
  @media (max-width: 1200px) {
    .hide {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .medium {
      display: block !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 16px !important;
  }
  gap: 10px;
`;

const Arrow = styled.i`
  border: solid white;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
`;

const UpArrow = styled(Arrow)`
  zoom: 1.4;
  transform: rotate(-135deg);
  border-color: ${CellThemes.green.percent.backgroundColor};
`;

const DownArrow = styled(Arrow)`
  zoom: 1.4;
  transform: rotate(45deg);
  border-color: ${CellThemes.red.percent.backgroundColor};
`;

const SameCircle = styled.div`
  width: 15px;
  height: 15px;
  border: solid white;
  border-radius: 15px;
`;

const RankTableRow = styled(TableRow)`
  background-color: #192e38;
  color: white;
  border-bottom-left-radius: 16px
  border-bottom-right-radius: 16px;
  gap: 10px;
 

  @media (max-width: 1200px) {
    .hidden {
        display: none;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
        .medium {
            display: block !important;
        }
    }


  }

    @media (max-width: 1200px) {
        .hide-medium {
            display: none;
        }
        .show-medium {
            display: block !important;
        }
    }

    .show-medium {
        display: none;
    }
`;

const RankRow = ({ totalRank, change, totalPercent }) => {
  return (
    <RankTableRow>
      <TableCell className="hidden" />

      <TableCell className="hide-medium" />

      <TableCell className="show-medium">
        <TableCellContent
          color={"white"}
          align={"right"}
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            fontFamily: "ContraxHV",
          }}
        >
          {" "}
          {getNumberWithOrdinal(parseInt(totalRank))}
        </TableCellContent>
      </TableCell>

      <TableCell>
        <TableCellContent
          color={change >= 0 ? "#5DC05E" : "#E06666"}
          align={"center"}
          style={{
            fontFamily: "24px",
            fontWeight: "bold",
            fontFamily: "ContraxHV",
          }}
        >
          {change > 0 ? "+" : ""}
          {change.toFixed(2)}%
        </TableCellContent>
      </TableCell>

      <TableCell className="hidden medium" />

      <TableCell>
        <TableCellContent
          color="#c8c8C8"
          align={"left"}
          style={{
            fontFamily: "24px",
            fontWeight: "bold",
            fontFamily: "ContraxHV",
          }}
        >
          {(totalPercent * 100).toFixed(2)}%
        </TableCellContent>
      </TableCell>

      <TableCell className="hidden">
        <TableCellContent
          color={"white"}
          align={"left"}
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            fontFamily: "ContraxHV",
          }}
        >
          {" "}
          {getNumberWithOrdinal(parseInt(totalRank))}
        </TableCellContent>
      </TableCell>
    </RankTableRow>
  );
};

const StyledNormalTable = styled.table`
  gap: 10px;

  @media (max-width: 1200px) {
    .hidden {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .medium {
      display: block !important;
    }
  }
`;

const TableBodyRow = ({
  title,
  description,
  newValue,
  oldValue,
  progressValue,
  oldValueRank,
  newValueRank,
  asPercentage,
  display,
}) => {
  return (
    <tr className="text-xs lg:text-sm text-black w-full ">
      {/* <td className="pl-20 lg:pl-60 pr-10 py-5">
        {title == "Experience" ? (
          <>
            <span
              className="text-md sm:text-2xl float-right font-bold"
              style={{ fontFamily: "ContraxSb", color: "#5DC05E" }}
            >
              +1
            </span>
          </>
        ) : (
          <SliderColumns>
            <ValueSliderWrapper>
              <ValueSlider
                value={oldValue}
                color={
                  newValue > oldValue
                    ? CellThemes.green.percent.old.textColor
                    : newValue < oldValue
                    ? CellThemes.red.percent.old.backgroundColor
                    : CellThemes.grey.percent.backgroundColor
                }
              />
            </ValueSliderWrapper>
            <ValueSliderWrapper>
              <ValueSlider
                value={newValue}
                color={
                  newValue > oldValue
                    ? CellThemes.green.percent.new.textColor
                    : newValue < oldValue
                    ? CellThemes.red.percent.new.backgroundColor
                    : CellThemes.grey.percent.backgroundColor
                }
              />
            </ValueSliderWrapper>
          </SliderColumns>
        )}
      </td> */}
      <td className="pl-2 lg:pl-60 pr-10 py-5"></td>
      <td
        className=" p-2 text-md sm:text-2xl "
        style={{ fontFamily: "ContraxSb", color: "#1E2E37" }}
      >
        <div className="flex gap-3 ">
          <Tooltip title={description}>
            <span
              className="px-2"
              style={{
                position: "relative",
                display: "inline-block",
                width: "100%",
                padding: "10px",
              }}
            >
              {title === "Experience" && (
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "80%",
                    height: "100%",
                  }}
                ></span>
              )}

              {title === "Value" && (
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: newValue + "%",
                    height: "100%",
                    backgroundColor:
                      progressValue < -1
                        ? "rgba(224,102,102, 0.3"
                        : progressValue < 1
                        ? "rgba(107,115,204, 0.3)"
                        : "rgba(71,147,73, 0.3)",
                  }}
                ></span>
              )}

              {title === "Aggression" && (
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: newValue + "%",
                    height: "100%",
                    backgroundColor:
                      progressValue < -1
                        ? "rgba(224,102,102, 0.3"
                        : progressValue < 1
                        ? "rgba(107,115,204, 0.3)"
                        : "rgba(71,147,73, 0.3)",
                  }}
                ></span>
              )}

              {title === "Points" && (
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: newValue + "%",
                    height: "100%",
                    backgroundColor:
                      progressValue < -1
                        ? "rgba(224,102,102, 0.3"
                        : progressValue < 1
                        ? "rgba(107,115,204, 0.3)"
                        : "rgba(71,147,73, 0.3)",
                  }}
                ></span>
              )}

              <div className="flex">
                {title == "Experience" && (
                  <img
                    style={{ objectFit: "contain" }}
                    class="w-6 mr-2 -ml-12 sm:w-8 sm:mr-4 sm:-ml-16"
                    src="/turtle.png"
                  />
                )}
                {title == "Aggression" && (
                  <img
                    style={{ objectFit: "contain" }}
                    class="w-6 h-5 mr-2 -ml-12  sm:w-8  sm:h-7 sm:mr-4 sm:-ml-16"
                    src="/lighting.png"
                  />
                )}

                {title == "Value" && (
                  <img
                    style={{ objectFit: "contain" }}
                    class="w-6 mr-2 h-5 -ml-12 sm:w-8 sm:h-7 sm:mr-4 sm:-ml-16"
                    src="/coin.png"
                  />
                )}

                {title == "Points" && (
                  <img
                    style={{ objectFit: "contain" }}
                    class="w-6 h-5 mr-2 -ml-12  sm:w-8 sm:h-6 sm:mr-4 sm:-ml-16"
                    src="/star.png"
                  />
                )}
                {title}
              </div>
            </span>
          </Tooltip>
        </div>
      </td>
      <td
        className="pr-2 text-md sm:text-2xl  text-right"
        style={{ fontFamily: "ContraxSb" }}
      >
        <Percentage
          value={
            asPercentage
              ? progressValue.toFixed(2)
              : display === "rank"
              ? oldValueRank - newValueRank
              : parseFloat(newValueRank - oldValueRank).toFixed(
                  title === "Experience" || title === "Points" ? 0 : 2
                )
          }
          asPercentage={asPercentage}
        />

        <span className="pl-3">|</span>
      </td>
      <td
        className="text-md sm:text-2xl  pl-2 text-left pr-20 lg:pr-60"
        style={{ fontFamily: "ContraxSb", color: "#1E2E37" }}
      >
        {asPercentage
          ? `${newValue.toFixed(2)}%`
          : display === "rank"
          ? getNumberWithOrdinal(newValueRank)
          : parseFloat(newValueRank - oldValueRank).toFixed(
              title === "Experience" || title === "Points" ? 0 : 2
            )}
      </td>
    </tr>
  );
};

const Percentage = ({ value, asPercentage }) => {
  return (
    <span
      style={{ color: value == 0 ? "" : value > 0 ? "#5DC05E" : "#E06666" }}
    >
      {value != 0 ? (value > 0 ? "+" : "") : ""}
      {value != 0 ? value : "-"}
      {asPercentage && value != 0 ? "%" : ""}
    </span>
  );
};

export default ({ data, closeModal, when }) => {
  const [display, setDisplay] = useState("percent");

  return (
    <>
      <div className="overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden rounded-lg">
        <div className=" rounded-lg" style={{ minWidth: "800px" }}>
          <table className="rounded-md bg-white sm:w-full lg:px-24">
            <thead
              className="rounded-t-md"
              style={{ backgroundColor: "#1e2e37" }}
            >
              <tr className="text-xs lg:text-sm text-white w-full ">
                <th className=" p-2"></th>
                <th className=" p-2 italic">Category</th>{" "}
                <th className=" p-2  text-right italic">
                  Deviation <span className="pl-3">|</span>
                </th>
                <th className=" p-2  text-left pr-20 lg:pr-60 italic">
                  <span className="hidden sm:inline">Rating </span>
                  <Select
                    className="w-20 sm:w-32 p-0"
                    size="small"
                    value={display}
                    onSelect={(e) => setDisplay(e)}
                  >
                    <option value="percent" selected>
                      Percentage
                    </option>
                    <option value="rank">Placement</option>
                    <option value="value">Results</option>
                  </Select>
                  {/* <span
                    className="text-gray-500 pl-2 underline cursor-pointer"
                    onClick={() => setDisplay("percent")}
                    style={{
                      color:
                        display === "percent"
                          ? "#EB56A7"
                          : "rgb(107, 114, 128)",
                      fontSize: "normal",
                    }}
                  >
                    %
                  </span>{" "}
                  <span className="text-gray-200"> or </span>{" "}
                  <span
                    style={{
                      color:
                        display === "rank" ? "#EB56A7" : "rgb(107, 114, 128)",
                      fontSize: "normal",
                    }}
                    className="underline cursor-pointer"
                    onClick={() => setDisplay("rank")}
                  >
                    P
                  </span>
                  <span className="text-gray-200"> or</span>{" "}
                  <span
                    style={{
                      color:
                        display === "value" ? "#EB56A7" : "rgb(107, 114, 128)",
                      fontSize: "normal",
                    }}
                    className="underline cursor-pointer"
                    onClick={() => setDisplay("value")}
                  >
                    #
                  </span> */}
                </th>
              </tr>
            </thead>

            <tbody className="bg-white text-black py-6">
              <tr className="h-8"></tr>

              <TableBodyRow
                title={"Experience"}
                description={
                  "Total games played. The more you play, the higher your experience!"
                }
                newValue={
                  (1 - data.after_match_statistic.experience.percent) * 100
                }
                oldValue={
                  (1 - data.before_match_statistic.experience.percent) * 100
                }
                newValueRank={
                  display === "rank"
                    ? data.after_match_statistic.experience.rank
                    : data.after_match_statistic.experience.value
                }
                oldValueRank={
                  display === "rank"
                    ? data.before_match_statistic.experience.rank
                    : data.before_match_statistic.experience.value
                }
                progressValue={
                  (1 - data.after_match_statistic.experience.percent) * 100 -
                  (1 - data.before_match_statistic.experience.percent) * 100
                }
                asPercentage={display === "percent"}
                display={display}
              />

              <TableBodyRow
                title={"Value"}
                description={
                  "Average amount of value extracted from hands that go to showdown"
                }
                newValue={
                  (1 - data.after_match_statistic.value_quotient.percent) * 100
                }
                oldValue={
                  (1 - data.before_match_statistic.value_quotient.percent) * 100
                }
                newValueRank={
                  display === "rank"
                    ? data.after_match_statistic.value_quotient.rank
                    : data.after_match_statistic.value_quotient.value.toFixed(2)
                }
                oldValueRank={
                  display === "rank"
                    ? data.before_match_statistic.value_quotient.rank
                    : data.before_match_statistic.value_quotient.value.toFixed(
                        2
                      )
                }
                progressValue={
                  (1 - data.after_match_statistic.value_quotient.percent) *
                    100 -
                  (1 - data.before_match_statistic.value_quotient.percent) * 100
                }
                asPercentage={display === "percent"}
                display={display}
              />

              <TableBodyRow
                title={"Aggression"}
                description={
                  "Average amount of chips accumulated from hands without showdown"
                }
                newValue={
                  (1 - data.after_match_statistic.aggression.percent) * 100
                }
                oldValue={
                  (1 - data.before_match_statistic.aggression.percent) * 100
                }
                newValueRank={
                  display === "rank"
                    ? data.after_match_statistic.aggression.rank
                    : data.after_match_statistic.aggression.value.toFixed(2)
                }
                oldValueRank={
                  display === "rank"
                    ? data.before_match_statistic.aggression.rank
                    : data.before_match_statistic.aggression.value.toFixed(2)
                }
                progressValue={
                  (1 - data.after_match_statistic.aggression.percent) * 100 -
                  (1 - data.before_match_statistic.aggression.percent) * 100
                }
                asPercentage={display === "percent"}
                display={display}
              />

              <TableBodyRow
                title={"Points"}
                description={"Average finishing score"}
                newValue={
                  (1 - data.after_match_statistic.msr.points.percent) * 100
                }
                oldValue={
                  (1 - data.before_match_statistic.msr.points.percent) * 100
                }
                newValueRank={
                  display === "rank"
                    ? data.after_match_statistic.msr.points.rank
                    : data.after_match_statistic.msr.points.value
                }
                oldValueRank={
                  display === "rank"
                    ? data.before_match_statistic.msr.points.rank
                    : data.before_match_statistic.msr.points.value
                }
                progressValue={
                  (1 - data.after_match_statistic.msr.points.percent) * 100 -
                  (1 - data.before_match_statistic.msr.points.percent) * 100
                }
                asPercentage={display === "percent"}
                display={display}
              />
              <tr className="h-8"></tr>
            </tbody>

            <tfoot style={{ backgroundColor: "#1e2e37" }}>
              <tr className="text-xs lg:text-sm text-white w-full">
                <th className=" p-2 py-8"></th>
                <th
                  className="p-2 text-md sm:text-2xl"
                  style={{ fontFamily: "ContraxSb" }}
                >
                  Rank
                </th>
                <th
                  className=" p-2 text-md sm:text-2xl text-right"
                  style={{ fontFamily: "ContraxSb" }}
                >
                  {display !== "value" && (
                    <>
                      <Percentage
                        value={
                          display === "percent"
                            ? (
                                data.before_match_statistic.total_percent *
                                  100 -
                                data.after_match_statistic.total_percent * 100
                              ).toFixed(2)
                            : data.before_match_statistic.total_rank -
                              data.after_match_statistic.total_rank
                        }
                        asPercentage={display === "percent"}
                      />{" "}
                      <span className="pl-3">|</span>
                    </>
                  )}
                </th>
                <th
                  className="p-2 text-md sm:text-2xl text-left"
                  style={{ fontFamily: "ContraxSb" }}
                >
                  {display === "percent"
                    ? `${(
                        (1 - data.after_match_statistic.total_percent) *
                        100
                      ).toFixed(2)}%`
                    : getNumberWithOrdinal(
                        data.after_match_statistic.total_rank
                      )}

                  {display !== "value" && (
                    <span className="sm:inline hidden">
                      {" "}
                      | 
                      {!(display === "percent")
                        ? `${(
                            (1 - data.after_match_statistic.total_percent) *
                            100
                          ).toFixed(2)}%`
                        : getNumberWithOrdinal(
                            data.after_match_statistic.total_rank
                          )}
                    </span>
                  )}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
